
  import ScreenSize from '@/mixins/ScreenSize';
  import {prepareImageUrl} from '@/helper/ImageHelper';
  export default {
    components: {
      RichText: () => import('@/components/elements/RichText'),
    },
    mixins: [ScreenSize],
    props: {
      testimonial: {
        type: Object,
        required: false,
        default: () => {},
      },
    },
    data() {
      return {
        imageUrl: require(process.env.NUXT_ENV_FALLBACK_CARD_IMAGE),
        logoUrl: '',
      };
    },
    created() {
      if (this.testimonial.image) {
        this.imageUrl = prepareImageUrl(this.testimonial.image.url);
      }
      if (this.testimonial.logo) {
        this.logoUrl = prepareImageUrl(this.testimonial.logo.url);
      }
    },
  };
